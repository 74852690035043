import Icon from "./Icon";
import React, {useRef} from "react";
import cn from 'classnames'
import {ShowMore} from "@re-dev/react-truncate";


const Review = ({photoSrc, adsLogoSrc, author, date, rate, text='', cardLink}) => {
  const ref = useRef(null)
  const toggleLines = (e) => {
    ref.current?.toggleLines(e)
  }

  return (
    <div className={cn('review', {'sm-loading': text.length === 0})}>
      <div className="review__title">
        <div className="review__user-photo sm-item-primary">
          {/*<img src={process.env.REACT_APP_API_DOMAIN + photoSrc} alt={author} height="40" className="" />*/}
          <img src={photoSrc ? photoSrc : "./assets/images/avatar.svg"} alt={author} height="35" width="35"/>
          {adsLogoSrc && (
            <div className="review__source">
              <img src={adsLogoSrc} alt=""/>
            </div>
          )}
        </div>
        <div className="review__info">
          {cardLink && (<a href={cardLink} className="review__user-name sm-item-secondary" target="_blank">{author}</a>)}
          {!cardLink && (<div className="review__user-name sm-item-secondary">{author}</div>)}
          <div className="review__details sm-item-secondary">
            <div className="review__rating" data-stars={rate}>
              <Icon name="star"/>
              <Icon name="star"/>
              <Icon name="star"/>
              <Icon name="star"/>
              <Icon name="star"/>
            </div>
            <div className="review__date">{date}</div>
          </div>
        </div>
      </div>
      <div className="review__text sm-item-secondary">
        <ShowMore
          ref={ref}
          lines={4}
          more={<button className="more-btn" onClick={toggleLines} >...еще</button>}
          less={<></>}
        >
          {text}
        </ShowMore>
      </div>
    </div>
  )
}
export default Review