import axios from "axios";

// Отправка ошибок
export const sendError = async (data) => {
  const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/react-logs/error-collector.php`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return true;
};