import {useCallback, useEffect, useRef, useState} from "react";
import Icon from "../Icon";
import {useReviewStore} from "../../store/review-store";
import Review from "../Review";
import {scrollWidth} from "../../utils/getScrollbarWidth";

const ModalReviews = ({active, onClose}) => {

  const [classVisible, setClassVisible] = useState('');
  const [classFade, setClassFade] = useState('');

  useEffect(() => {
    if (active) {
      scrollWidth(true)
      setClassVisible('modal_visible');
      setTimeout(() => {
        setClassFade('modal_fade');
      }, 1);
    } else {
      scrollWidth()
      setClassFade('');
      setTimeout(() => {
        setClassVisible('');
      }, 300);
    }
  }, [active]);


  const observerRef = useRef(null);

  const reviews = useReviewStore((state) => state.reviews);
  const totalReviews = useReviewStore((state) => state.totalReviews);
  const updateReviews = useReviewStore((state) => state.getReviews);
  const [page, getCurrentPage] = useReviewStore((state) => [state.page, state.getCurrentPage]);
  const isLoading = useReviewStore((state) => state.isLoading);

  const getPage = () => getCurrentPage();

  useEffect(() => {
    let touchStartX = 0;
    let touchStartY = 0;

    const handleTouchStart = (event) => {
      const touch = event.touches[0];
      touchStartX = touch.clientX;
      touchStartY = touch.clientY;
    };

    const handleTouchEnd = async (event) => {
      const touch = event.changedTouches[0];
      const touchEndY = touch.clientY;
      const deltaY = touchEndY - touchStartY;

      const page = getPage()

      if (
        deltaY < 0 &&
        observerRef.current.scrollHeight - (observerRef.current.scrollTop + window.innerHeight) < 300 &&
        !isLoading &&
        reviews.length < totalReviews
      ) {
        updateReviews(page+1);
      }
    };

    observerRef.current.addEventListener('touchstart', handleTouchStart);
    observerRef.current.addEventListener('touchend', (e) => handleTouchEnd(e, page));

    return () => {
      if(observerRef.current) {
        observerRef.current.removeEventListener('touchstart', handleTouchStart);
        observerRef.current.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, []);

  const scrollHandler = useCallback((e) => {
    if (
      observerRef.current.scrollHeight - (observerRef.current.scrollTop + window.innerHeight) < 300 &&
      e.deltaY > 0 &&
      !isLoading &&
      reviews.length < totalReviews
    ) {
      updateReviews(page + 1);
    }
  }, [isLoading, page, updateReviews]);

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.addEventListener('scroll', scrollHandler);
      observerRef.current.addEventListener('wheel', scrollHandler);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.removeEventListener('scroll', scrollHandler);
        observerRef.current.removeEventListener('wheel', scrollHandler);
      }
    };
  }, [scrollHandler]);

  useEffect(() => {
    updateReviews(1);
  }, [updateReviews]);

  return (
    <div className={`modal modal_type-2 modal-reviews ${classVisible} ${classFade}`} data-popup="reviews">
      <div className="modal__inner">
        <div className="modal__wrap" ref={observerRef}>
          <div className="modal__header">
            <div onClick={onClose} className="modal__close modal-close">
              <Icon name="close"/>
            </div>
          </div>
          <h2 className="h2 modal__title-sticky">Все отзывы</h2>
          <div className="modal__content">
            <div className="reviews">
              {
                reviews.map((review, index) => (<Review {...review} key={index}/>))
              }
              {isLoading && (<p className="reviews__loading">Загрузка</p>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ModalReviews