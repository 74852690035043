import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from "./components/ErrorBoundary";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ErrorBoundary>
  <App/>
  </ErrorBoundary>
);

serviceWorkerRegistration.register();
//reportWebVitals();
